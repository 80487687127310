<template>
  <v-app id="main">
    <v-main style="background: #2d2d2d;">
      <v-card class="d-flex flex-column" flat height="100vh">
        <v-card flat style="z-index: 1;" class="rounded-b-lg">
          <v-col class="Content rounded-b-lg">
            <v-row class="Top-row rounded-b-lg" align="center" justify="center" no-gutters>
              <v-img class="Logo" src="/../../img/svg/vc-logo-icon.svg"></v-img>
              <p class="Title">Virtual Meeting</p>
            </v-row>
          </v-col>
        </v-card>

        <v-card id="content" class="d-flex flex-column rounded-0" flat height="100%" style="background: #2d2d2d; margin: -10px 0 0px 0;">
          <router-view />
        </v-card>

        <v-card flat id="bottom-row"  style="z-index: 1;">
          <v-col class="Content">
            <v-row class="Bottom-row rounded-t-lg" align="center" justify="center" no-gutters />
          </v-col>
        </v-card>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),

  mounted() {
    document.getElementById("main").style.height = (window.innerHeight)+'px';
    window.scrollTo(0, 0);
    
    window.addEventListener('resize', () => {
        document.getElementById("main").style.height = (window.innerHeight)+'px';
        window.scrollTo(0, 0);
    })
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.Content {
  position: relative;
  padding: 0;
  margin: 0;
  height: 100%;
  background: transparent;
}

.Top-row {
  padding: 0;
  margin: 0;
  height: 85px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.72), inset 0 1px 7px 0 rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(92deg, #23425a 0%, #243941 99%);
}

.Bottom-row {
  padding: 0;
  margin: 0;
  height: 34px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.72), inset 0 1px 7px 0 rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(92deg, #23425a 0%, #243941 99%);
}

.Logo {
  padding: 0;
  margin: 0;
  max-width: 26px;
  max-height: 20px;
}

.Title {
  padding: 0;
  margin: 0 0 0 22px;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

@media screen and (max-width: 960px) {
  .Title {
    padding: 0;
    margin: 0 0 0 22px;
    font-family: 'Lato';
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
}
</style>
