export default {
    namespaced: true,
    state: {
        subject: null,
        staffName: null,
        meetingNo: null,
        availableStartTime: null,
    },
    mutations: {
        SET_TOKENINFO(state, options) {
            state.subject = options.subject;
            state.staffName = options.staffName;
            state.meetingNo = options.meetingNo;
            state.availableStartTime = new Date(options.availableStartTime).format('YYYY/MM/DD HH:mm:ss');
        }
    },
    actions: {
        setTokenInfo(context, options) {
            context.commit('SET_TOKENINFO', {
                subject: options.subject,
                staffName: options.staffName,
                meetingNo: options.meetingNo,
                availableStartTime: options.availableStartTime,
            });
        }
    }
}

Date.prototype.format = function (fmt) {
    var o = {
      "M+": this.getMonth() + 1,
      "D+": this.getDate(),
      "H+": this.getHours(), 
      "m+": this.getMinutes(),
      "s+": this.getSeconds(),
      "Q+": Math.floor((this.getMonth() + 3) / 3),
      "S": this.getMilliseconds()
    };
    if (/(Y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" +  k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" +  o[k]).substr(("" + o[k]).length)));
    return fmt;
  }