import axios from 'axios'
import router from '../router'
import store from '../store';
import { tip, toError, toWaiting, toNoPermission } from './util.js'

const errorHandle = (status, msg) => {
    switch (status) {
        case '400':
            toError();
            break;

        case '401':
            toNoPermission();
            break;

        case '403':
            toWaiting();
            break;

        default:
            console.log('unknown error： ' + msg);
    }
}

var instance = axios.create({
    baseURL: '/'
})

// instance.interceptors.request.use((config) => {
//     const token = store.state.auth.token;
//     token && (config.headers.Authorization = 'Bearer ' + token);
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// });

instance.interceptors.response.use((response) => {
    // Because api server only return http status 200, ture http status is in data
    if(response.data.status !== '200') {
        errorHandle(response.data.status, response.data.message); //if api returns normal http status, use *response.status*
    }
    return response;
}, (error) => {
    const { response } = error;

    if (response) {
        errorHandle(response.status, response.data.message);
        return Promise.reject(error);
    } else {
        if (!window.navigator.onLine) {
            tip("Please refresh the webpage");
        } else {
            return Promise.reject(error);
        }
    }
})

export default function (method, url, data = null) {
    method = method.toLowerCase();
    if (method == 'post') {
        return instance.post(url, data);
    } else if (method == 'get') {
        return instance.get(url, { params: data });
    } else if (method == 'delete') {
        return instance.delete(url, { params: data });
    } else if (method == 'put') {
        return instance.put(url, data);
    } else {
        console.error('unknown method: ' + method);
        return false
    }
}