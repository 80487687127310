import req from './https.js'
import Config from "../plugins/Config"

const apiURL = Config.URL.API_SERVER + '/meeting';

const _meeting = {
    checkMeetingStatus(params) {
        return req('post', apiURL + '/check_meeting_status', params);
    },
    addMeetingAttendee(params) {
        return req('post', apiURL + '/add_meeting_attendee', params);
    },
    finishMeeting(params) {
        return req('post', apiURL + '/finish_meeting', params);
    }
}

export default _meeting;