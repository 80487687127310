import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme:{
        themes:{
            light :{
                primary: '#243C49',
            },
            dark :{
                primary: '#243C49',
            }
            
        }
    }
});
