export default {
    namespaced: true,
    state: {
        name: null,
        token: null
    },
    mutations: {
        SET_AUTH(state, options) {
            state.name = options.name;
            state.token = options.token;
        }
    },
    actions: {
        setAuth(context, options) {
            context.commit('SET_AUTH', {
                name: options.name,
                token: options.token
            });
        }
    }
}