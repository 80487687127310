import router from '../router'

export const tip = msg => {
    console.log(msg);
}

export const toError = () => {
    router.replace({
        name: 'Error',
        // query: {
        //     redirect: router.currentRoute.fullPath
        // }
    });
}

export const toWaiting = () => {
    router.replace({
        name: 'Waiting',
        query: {
            redirect: router.currentRoute.fullPath
        }
    });
}

export const toNoPermission = () => {
    router.replace({
        name: 'NoPermission',
        // query: {
        //     redirect: router.currentRoute.fullPath
        // }
    });
}