import req from './https.js'
import Config from "../plugins/Config"

const apiURL = Config.URL.API_SERVER + '/token';

const _token = {
    // decryptToken(params) {
    //     return req('post', apiURL + '/decrypt_token', params);
    // }
}

export default _token;