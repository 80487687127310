export default {
    namespaced: true,
    state: {
        users: []
    },
    mutations: {
        SET_USERS(state, options) {
            options.users.forEach(function (user) {
                state.users[user.userid] = user.name;
            });
        }
    },
    actions: {
        setUsers(context, options) {
            context.commit('SET_USERS', {
                users: options.users,
            });
        }
    }
}