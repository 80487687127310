import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: () => import('../views/Home.vue'),
  // },
  {
    path: '/Admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
  },
  {
    path: '/User',
    name: 'User',
    component: () => import('../views/User.vue'),
  },
  {
    path: '/Waiting',
    name: 'Waiting',
    component: () => import('../views/Waiting.vue'),
  },
  {
    path: '/NoPermission',
    name: 'NoPermission',
    component: () => import('../views/NoPermission.vue'),
  },
  {
    path: '/Error',
    name: 'Error',
    component: () => import('../views/Error.vue'),
  },
  {
    path: '/Leave',
    name: 'Leave',
    component: () => import('../views/Leave.vue'),
  },
  {
    path: '/AdminVideo',
    name: 'AdminVideo',
    component: () => import('../views/AdminVideo.vue'),
  },
  {
    path: '/Video',
    name: 'Video',
    component: () => import('../views/Video.vue'),
  },
  {
    path: '/BrowserSupport',
    name: 'BrowserSupport',
    component: () => import('../views/BrowserSupport.vue'),
  },
  { 
    path: "*",
    component: () => import('../views/PageNotFound.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
